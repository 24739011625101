// Card animation on hover
.homeFeature:not(.homefeaturecategory-homeboximpactstats):not(.homefeaturecategory-homeboxquickgiving),
.feedItem,
.listedPost {
    transition-delay: .2s;
    top: 0;
    &:hover {
        top: -10px;
    }
}
.searchResultsArea .listedPost {
    top: auto;
    &:hover {
        top: auto;
    }
}
// Make even numbered feeds light grey background
.homeFeed:nth-child(even) {
    background-color: $grey-lightest;
}
// Maintains aspect ratio 16:9 for video on mobile
.postVideoObjectEmbed {
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    position: relative;
    margin: $spacer*2 0;
    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
// Fix search input
.header-search #siteSearch {
    padding-right: 38px;
}
// Remove border radius on burger bar menu
@media (max-width: 501px) {
    .menuMainAlt {
        border-radius: 0;
    }
}
// Icon in button
.cta-button {
    // font-size: 0.88em;
    &:before {
        font-family: 'FontAwesome', icon;
        margin-right: 0.25em;
        transform: scale(1);
        transition: all .3s cubic-bezier(0.47, 1.64, 0.49, 2.04);
    }
    &:hover {
        &:before {
            transform: scale(1.3);
        }
    }
}
a.cta-button.donateBtn,
a.donateBtn,
a.donate-button {
    background-color: $donate-colour;
    color: black;
    &:before {
        content: "\f004";
        font-family: 'FontAwesome', icon;
        margin-right: 0.25em;
        transition: all .3s cubic-bezier(0.47, 1.64, 0.49, 2.04);
    }
    &:hover {
        &:before {
            transform: scale(1.3);
        }
    }
}
.mainCallToAction a.cta-button.referBtn {
    background-color: $brand-secondary;
    color: white;
    &:before {
        content: "\f277";
    }
    &:hover {
        background-color: $brand-secondary;
        color: white;
    }
}
a.fundraiseBtn {
    &:before {
        content: "\f2c8";
        font-family: 'FontAwesome', icon;
        margin-right: 0.25em;
        transition: all .3s cubic-bezier(0.47, 1.64, 0.49, 2.04);
    }
    &:hover {
        &:before {
            transform: scale(1.3);
        }
    }
}
// Footer stuff
.pageFooter {
    padding-bottom: 0;
}
.footerBox.Footerlinks {
    ul {
        display: flex;
        justify-content: center;
        @media (max-width: map-get($breakpoints, lg)) {
            display: block;
            text-align: center;
        }
        li {
            font-weight: $font-weight-bold;
            a {
                padding-left: 0.5em;
                padding-right: 0.5em;
                text-decoration: none;
            }
        }
    }
}
.footerBox.Footercontactus {
    text-align: center;
    h3 {
        font-size: 1.125em;
    }
    p {
        font-size: 0.875em;
        margin: 0;
    }
    .cta-button {
        color: white;
        text-decoration: none;
        margin-bottom: 1em;
    }
}
.footerBox.Footerlogos {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    grid-column: 1 / 17;
    width: 100%;
    padding: $spacer*2 0;
    margin: 0;
    img {
        display: block;
        max-width: 180px;
        height: auto;
        margin: 5px $gap-width;
    }

    @media (max-width: map-get($breakpoints, sm)) {
      flex-direction: column;
    }
}

// Bump this up a bit because we're still negatively margin the intro
@media (max-width: $carousel-breakpoint) {
  .carouselImageMultiple .carouselSlideDetail {
    padding-bottom: calc(2.5rem + 40px);
  }

  .carouselButtons {
    padding-bottom: 2.5rem;
  }
}
