//
// Environment
//



//
// Config values
//

$platform-name: 'npafamilyandcommunityservices';


//
// Colours
//

$brand-primary: #066097;
$brand-secondary: #bb181d;

$donate-colour: #e7dd14;

$brand-grey: #e4e4d8;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     lighten($brand-grey, 5%);
$white:             #fff !default;


// Utility colours


//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;
$border-radius: 5px;
$border-colour: $brand-grey;


// Breakpoints


// Spacers
$spacer: 1rem;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'logo.png';
$logo-width: 510px;
$logo-height: 120px;


//
// Typography
//

$font-family-base: 'Fira Sans', sans-serif;
$font-weight-bold: 800;
// $font-size-base: 1.125rem;

$headings-margin-top: 2em; // This will only affect headings within post content

// Links

// Icon font


//
// Buttons
//

$btn-background-colour: $grey-dark;
$btn-padding-y: .75em;


// Donate button
$donate-btn-background-colour: $donate-colour;


//
// Social icons
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true

$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: brand; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//


// Field groups


//
// Tables
//



//
// Card
//
// $card-box-shadow: 0 0 20px rgba(black,0.1);
$card-heading-font-size: 1.25em;
$card-footer-background-colour: white;


// Card text overlay

// Card text over

// Card side by side


// Card hover state
// $card-hover-details-background-colour: $brand-primary;
$card-hover-box-shadow: 0 10px 20px rgba(black,0.05);
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;


//
// Menu admin
//

$menu-admin-enabled: false;


//
// Header
//


// Header content
$header-content-margin-top: $spacer/4;
$header-content-margin-bottom: $spacer/4;

// Tagline

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -42px;
$header-search-input-background-colour: $grey-lightest;
$header-search-input-border-width: 0 0 0 0; // t-r-b-l

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: darken($brand-grey, 10%);

// Social icons


//
// Sticky header
//



//
// Navigation
//

$nav-background-colour: $brand-primary;


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-size: 1.25em;
$nav-top-level-item-hover-colour: lighten($brand-primary, 15%);
$nav-top-level-item-hover-background-colour: white;
// $nav-top-level-chevrons-enabled: true;
// $nav-top-level-chevrons-colour: lighten($brand-primary, 15%);

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 10px 20px rgba(black, 0.1);
$nav-submenu-item-hover-colour: lighten($brand-primary, 15%);
$nav-submenu-item-hover-background-colour: transparent;
$nav-submenu-chevrons-colour: $brand-primary;

// Burger button
$burger-btn-bar-breakpoint: 501px; // What viewport width do you want the burger button to pop under the header full width?


// Nav normal

$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-align-items: center; // left, center, right
$nav-normal-margin-bottom: 0;
$nav-normal-border-radius: 0;


//
// Carousel
//

$carousel-max-width: 100%;
$carousel-details-style: overlay; // overlay, adjacent or below
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-details-padding: $spacer * 2;
$carousel-details-max-width: 500px;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-button-gap: 0.5em;
$carousel-controls-margin-x: 1em;
$carousel-controls-button-background-colour: white;
$carousel-controls-icon-colour: $grey-dark;

// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel after breakpoint


//
// Home intro
//

$home-intro-background-colour: white;
$home-intro-max-width: $container-max-width;
$home-intro-margin-top: -3rem;
$home-intro-padding-y: $spacer * 3;
$home-intro-font-size: 1.5em;
$home-intro-content-max-width: $container-max-width - 150px;
$home-intro-colour: $brand-secondary;


//
// Home features
//

$home-features-padding-top: $spacer;
$home-features-max-width: $container-max-width - 150px;


//
// Impact stats
//

$impact-stats-background-image: 'star.svg';
$impact-stats-background-image-size: 40%;
$impact-stats-background-colour: $donate-colour;
$impact-stats-background-image-opacity: 1;


// Heading
$impact-stats-heading-margin-bottom: $spacer;

// Individual stat

// Figure

// Summary


//
// Home feeds
//


// Feeds title
$feeds-title-colour: $brand-secondary;

// Feed item

// Event feed items

// Twitter feed


//
// Footer
//

$footer-prefab: 6;


// Footer admin links

// Newsletter
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-background-colour: $brand-secondary;
$newsletter-button-background-colour: $grey-dark;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;



//
// Page title
//

$page-title-margin-top: 2em;



//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts


// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//

$breadcrumb-max-width: $container-max-width;


//
// Blockquote
//

$blockquote-font-size: 1.5em; // Em unit will cascade to children


//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//

// Donation amounts


//
// Quick giving panel
//

$quick-giving-donation-amount-background-colour: $brand-primary;


//
// Home quick giving panel
//

$home-quick-giving-donation-amount-background-colour: $brand-primary;
$home-quick-giving-donate-btn-background-colour: $grey-dark;
$home-quick-giving-heading-colour: $brand-secondary;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//

$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $brand-primary;



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)


// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite nav


//
// Cookie consent
//